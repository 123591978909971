<template>
  <div>
    <div>
      <div class="md-title">All Team Members</div>
      <d-b-table
          title="Team Members"
          :load-path="sever_address"
          :delete-path="sever_address"
          :patch-path="sever_address"
          :show-index="false"
          :columns="{'Name':'name', 'Team':'team', 'Team Description':'teamDescription',  'Importance':'importance'}"
          :patch-fields="{
              'Name':'name',
              'Team':'team',
              'Team Description':'teamDescription',
              'About Me': 'description',
              'Academic Description': 'academicDescription',
              'Importance':'importance',
              'Image': 'img'
          }"
          patch-dialog-title="Edit Team Member"
      />
    </div>
    <div class="elem">
      <div class="md-title">Add Team Member</div>
      <form>
        <md-field>
          <label>Name</label>
          <md-input v-model="newMember.name"></md-input>
        </md-field>
        <md-autocomplete v-model="newMember.team" :md-options="teamOptions">
          <label>Team</label>
        </md-autocomplete>
        <md-field>
          <label>Team Description</label>
          <md-input v-model="newMember.teamDescription"></md-input>
        </md-field>
        <md-field>
          <label>About Me</label>
          <md-textarea v-model="newMember.description" md-autogrow></md-textarea>
        </md-field>
        <md-field>
          <label>Academic Description</label>
          <md-textarea v-model="newMember.academicDescription" md-autogrow></md-textarea>
        </md-field>
        <md-field>
          <label>Importance</label>
          <md-input v-model="newMember.importance" type="Number"></md-input>
        </md-field>
        <md-field>
          <label>Image</label>
          <md-input v-model="newMember.img"></md-input>
          <img :src="newMember.img" style="height:40px" :alt="newMember.name"/>
        </md-field>

        <md-switch v-model="newMember.published">Show on Landing Page</md-switch>
        <md-button class="md-primary md-raised" @click="addTeamMember">
          Add
        </md-button>
      </form>
    </div>
  </div>
</template>
<script>
import DBTable from "@/components/DBTable";
import {axiosPost} from "@/utils/axiosWrapper";

export default {
  data() {
    return {
      sever_address: '/team/',
      team: [],
      newMember: {
        name: "",
        team: "",
        teamDescription: "",
        description: "",
        importance: 0,
        img: "",
        published: false
      },
      teamOptions: ["Management", "Peer Support/Social Media", "Written Content", "Self Support", "Design", "Development","Funding"]
    }
  },
  methods: {
    addTeamMember: function () {
      axiosPost(this.sever_address, this.newMember)
          .then((r) => {
                console.log(r)
                this.newMember = {
                  name: "",
                  team: "",
                  teamDescription: "",
                  academicDescription: "",
                  description: "",
                  importance: 0,
                  img: "",
                  published: false
                }
              }
          ).catch((e) => {
        console.warn(e)
      })
    }
  },
  async mounted() {
  },
  components: {
    DBTable
  }
}
</script>
<style scoped>
.elem {
  margin-top: 20px;
}
</style>
